//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CityModel } from '@/modules/city/city-model';
// import { i18n } from '@/i18n';

const { fields } = CityModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
]);

export default {
  name: 'app-city-form-page',

  props: ['id'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      record: 'city/form/record',
      findLoading: 'city/form/findLoading',
      saveLoading: 'city/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
  },
  mounted() {

  },
  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record);
  },

  methods: {
    ...mapActions({
      doFind: 'city/form/doFind',
      doNew: 'city/form/doNew',
      doUpdate: 'city/form/doUpdate',
      doCreate: 'city/form/doCreate',
      setCitys: 'city/form/setCitys',
    }),
     i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model);
      values.name = {
        en: this.model.name,
        ar: this.model.name,
      }
      if (this.isEditing) {
        return await this.doUpdate({
          id,
          values,
        });
      } else {
        let record = await this.doCreate(values);
        return record;
      }
    },
  },
};
