import { render, staticRenderFns } from "./city-form-page.vue?vue&type=template&id=388a89da&scoped=true&"
import script from "./city-form-page.vue?vue&type=script&lang=js&"
export * from "./city-form-page.vue?vue&type=script&lang=js&"
import style0 from "./city-form-page.vue?vue&type=style&index=0&id=388a89da&lang=scss&scoped=true&"
import style1 from "./city-form-page.vue?vue&type=style&index=1&id=388a89da&lang=scss&scoped=true&"
import style2 from "./city-form-page.vue?vue&type=style&index=2&id=388a89da&scoped=true&lang=css&"
import style3 from "./city-form-page.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./city-form-page.vue?vue&type=style&index=4&lang=scss&"
import style5 from "./city-form-page.vue?vue&type=style&index=5&id=388a89da&lang=scss&scoped=true&"
import style6 from "./city-form-page.vue?vue&type=style&index=6&id=388a89da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388a89da",
  null
  
)

export default component.exports
import {QForm,QSeparator,QInput,QBtn,QSpinnerIos,QItem,QField,QSelect} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QSeparator,QInput,QBtn,QSpinnerIos,QItem,QField,QSelect})
